import { Component, inject, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@env';
import { CmsClient } from './ngrx-data/cms-client.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  ngOnInit(): void {
    this.#cmsClient.collectionEntityClient().getAll();
  }
  #cmsClient = inject(CmsClient);

  protected areCoursesPristine$ = this.#cmsClient
    .changeState$()
    .pipe(map(changes => Object.keys(changes).length === 0));

  protected environment = environment;

  saveChanges(): void {
    this.#cmsClient.saveChanges();
  }

  undoChanges(): void {
    this.#cmsClient.undoChanges();
  }
}
