import { inject, Injectable } from '@angular/core';
import { DefaultHttpUrlGenerator, HttpResourceUrls } from '@ngrx/data';
import { IdentityService } from '../../identity/identity.service';
import { CmsContextName } from './entity-metadata';

@Injectable({ providedIn: 'root' })
export class CmsHttpUrlGenerator extends DefaultHttpUrlGenerator {
  #identity = inject(IdentityService);

  get #companyId() {
    return this.#identity.getCompanyId();
  }

  protected getResourceUrls(
    entityName: string,
    endpointUrl: string,
    trailingSlashEndpoints?: boolean
  ): HttpResourceUrls {
    const urls = super.getResourceUrls(entityName, endpointUrl, trailingSlashEndpoints);

    // TODO: Document
    const companyCourseEntityName: CmsContextName = 'CompanyCourse';

    if (entityName == companyCourseEntityName) {
      return {
        entityResourceUrl: `${endpointUrl}/companies/${this.#companyId}/courses`,
        collectionResourceUrl: `${endpointUrl}/companies/${this.#companyId}/courses`
      };
    }

    return urls;
  }
}
