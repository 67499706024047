<header class="flex bg-white shadow-md gap-12 justify-between px-12 h-[var(--toolbar-height)] items-center">
  <a
    routerLink="/"
  >
    <h1
      data-testid="heading-title"
      class="navigation-title"
    >
      {{ 'navigation.title' | transloco }}
    </h1>
  </a>

  <div class="flex justify-between flex-1 h-full">
    <div class="navigation-items">
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        class="navigation-item"
        routerLink="/cyber-portal/inhaltspflege/cms/courses"
      >
        Globale Kurse
      </a>
      <a
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLinkActive]="'active'"
        class="navigation-item"
        routerLink="/cyber-portal/inhaltspflege/cms/company-courses"
      >
        Unternehmenskurse
      </a>
    </div>

    <nav class="flex items-center gap-8">
      <button
        (click)="undoChanges()"
        [disabled]="areCoursesPristine$ | async"
        mat-raised-button
      >
        {{ 'cms.discardChangesButton' | transloco }}
      </button>
      <button
        (click)="saveChanges()"
        [disabled]="areCoursesPristine$ | async"
        color="primary"
        mat-raised-button
      >
        {{ 'cms.saveChangesButton' | transloco }}
      </button>
    </nav>
  </div>
</header>

<section class="grid container mx-12">
  <router-outlet></router-outlet>
</section>
