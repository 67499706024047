import { createReducer, on } from '@ngrx/store';
import { cmsContextActivated } from './course.actions';
import { CmsContextName } from '../ngrx-data/entity-metadata';

export type CourseState = {
  activeCourseContext: CmsContextName;
};

const initialState: CourseState = {
  activeCourseContext: 'CourseCMS'
};

export const courseReducer = createReducer(
  initialState,
  on(cmsContextActivated, (state, { name }) => ({ ...state, activeCourseContext: name }))
);
