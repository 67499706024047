import { DefaultDataServiceConfig, EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';
import { environment } from '@env';

export type CmsContextName = 'CourseCMS' | 'CompanyCourse';

const entityMetadata: EntityMetadataMap = {
  CourseCMS: {},
  CompanyCourse: {}
};

const pluralNames = {
  CourseCMS: 'CourseCMS',
  CompanyCourse: 'CompanyCourses'
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: `${environment.apiUrl}`,
  timeout: 30000 // request timeout
};
