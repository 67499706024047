import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DefaultDataServiceConfig, EntityDataModule, HttpUrlGenerator } from '@ngrx/data';
import { TranslateModule } from '../translate.module';
import { CmsRoutingModule } from './cms-routing.module';
import { CmsComponent } from './cms.component';
import { defaultDataServiceConfig, entityConfig } from './ngrx-data';
import { FeedbackModule } from '../feedback/feedback.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { HasRoleDirective } from '../identity/has-role.directive';
import { CmsHttpUrlGenerator } from './ngrx-data';
import { StoreModule } from '@ngrx/store';
import { courseFeatureKey } from './store/course.selectors';
import { courseReducer } from './store/course.reducer';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';

@NgModule({
  declarations: [CmsComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(courseFeatureKey, courseReducer),
    EntityDataModule.forRoot(entityConfig),
    MatLegacySnackBarModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    CmsRoutingModule,
    TranslateModule,
    FeedbackModule,
    LayoutsModule,
    MatLegacyTabsModule,
    HasRoleDirective
  ],
  providers: [
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig },
    { provide: HttpUrlGenerator, useClass: CmsHttpUrlGenerator }
  ]
})
export class CmsModule {}
