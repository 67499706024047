import { inject, NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CmsComponent } from './cms.component';
import { Store } from '@ngrx/store';
import { cmsContextActivated } from './store/course.actions';
import { CoursesEditComponent } from './courses/courses-edit/courses-edit.component';
import { ModuleEditComponent } from './courses/module-edit/module-edit.component';
import { LessonEditComponent } from './courses/lesson-edit/lesson-edit.component';

const routes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'cms/courses'
  },
  {
    path: 'cms',
    component: CmsComponent,
    children: [
      {
        path: 'courses',
        loadComponent: () => import('./courses/global-courses.view.component').then(m => m.GlobalCoursesViewComponent),
        canActivate: [
          () => {
            inject(Store).dispatch(cmsContextActivated({ name: 'CourseCMS' }));
          }
        ]
      },
      { path: 'courses/:courseId/edit', component: CoursesEditComponent },
      { path: 'courses/:courseId/modules/:moduleId/edit', component: ModuleEditComponent },
      {
        path: 'courses/:courseId/modules/:moduleId/lessons/:lessonId/edit',
        component: LessonEditComponent
      },
      //   Company Courses
      {
        path: 'company-courses',
        loadComponent: () =>
          import('./courses/company-courses.view.component').then(m => m.CompanyCoursesViewComponent),
        canActivate: [
          () => {
            inject(Store).dispatch(cmsContextActivated({ name: 'CompanyCourse' }));
          }
        ]
      },
      { path: 'company-courses/:courseId/edit', component: CoursesEditComponent },
      { path: 'company-courses/:courseId/modules/:moduleId/edit', component: ModuleEditComponent },
      {
        path: 'company-courses/:courseId/modules/:moduleId/lessons/:lessonId/edit',
        component: LessonEditComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CmsRoutingModule {}
